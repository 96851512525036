import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'

export const query = graphql`
query($slug: String!) {
  markdownRemark(fields: { slug: { eq: $slug } }) {
    frontmatter {
      title
      date
    }
    html
  }
}
`
const Post = ({ data }) => {
  const { markdownRemark: { frontmatter, html }} = data
  return ( 
    <Layout>
      <h1>{frontmatter.title}</h1>
      <h2>{frontmatter.date}</h2>
      <div dangerouslySetInnerHTML={{__html: html}}></div>
    </Layout>
  )
 }

 export default Post
